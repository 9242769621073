import React, { useState, useEffect } from 'react';

import iconAviso from '../../../resources/img/icon-aviso-modal.svg';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import Funcoes from '../../../Utils/functions';
import ApiService from '../../../Utils/services';

import { Line } from 'react-chartjs-2';

export default function Dashboard() {

    const bancos = [
        {
            "value": "",
            "text": "Selecione"
        },
        {
            "value": "001",
            "text": "Banco do Brasil"
        },
        {
            "value": "151",
            "text": "Banco Nossa Caixa S.A"
        },
        {
            "value": "237",
            "text": "Banco Bradesco S.A."
        },
        {
            "value": "422",
            "text": "Banco Safra S.A."
        },
        {
            "value": "033",
            "text": "Banco Santander Brasil S.A."
        },
        {
            "value": "104",
            "text": "Caixa Econômica Federal"
        },
        {
            "value": "477",
            "text": "Citibank N.A."
        },
        {
            "value": "341",
            "text": "Banco Itaú S.A."
        },
        {
            "value": "748",
            "text": "Banco Cooperativo Sicredi S.A."
        },
        {
            "value": "756",
            "text": "Sicoob / Bancoob"
        },
        {
            "value": "260",
            "text": "Nubank"
        },
        {
            "value": "077",
            "text": "Banco Inter"
        },
        {
            "value": "212",
            "text": "Banco Original"
        },
        {
            "value": "735",
            "text": "Banco Neon"
        }
    ];

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('/home');
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [today, setToday] = useState('');
    const [filter, setFilter] = useState(1);
    const [user, setUser] = useState({data: { produtos: [] } });
    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [numero, setNumero] = useState('');
    const [valor, setValor] = useState(0);
    const [senha, setSenha] = useState('');
    const [saldo, setSaldo] = useState({
        saldo_disponivel: '0,00',
        saldo_bloqueado: '0,00',
        total_contas: '0,00'
    });
    const [resumo, setResumo] = useState({});
    const [mainChart, setMainChart] = useState({
        data: {
            labels: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h',
                '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            datasets: [
                {
                    label: "Entrada", fill: false, lineTension: 0.1, backgroundColor: '#44DA6A', borderColor: '#44DA6A',
                    borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#44DA6A',
                    pointBackgroundColor: '#44DA6A', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#44DA6A',
                    pointHoverBorderColor: '#44DA6A', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                    data: []
                },
                {
                    label: 'Saída', fill: false, lineTension: 0.1, backgroundColor: '#EB5757', borderColor: '#EB5757',
                    borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#EB5757',
                    pointBackgroundColor: '#EB5757', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#EB5757',
                    pointHoverBorderColor: '#EB5757', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                    data: []
                }
            ]
        },
        options: {
            showScale: true, showLines: true, legend: { display: false, },
            scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true, min: 0, max: 200000,
                        callback: function (value) {
                            return value < 1000 ? value : Funcoes.nFormatter(value);
                        }
                    }
                }]
            }
        }
    })

    useEffect(() => {
        setToday(Funcoes.geraDataExtenso());
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        setUser(userData);
        carregaSaldos(userData.token);
    }, []);

    const carregaSaldos = (token) => {
        setLoading(true);
        ApiService.get('dashboard/saldo', token)
            .then(result => {
                setSaldo({
                    saldo_disponivel: Funcoes.float2Preco(result.data.saldo_disponivel),
                    saldo_bloqueado: Funcoes.float2Preco(result.data.saldo_bloqueado),
                    total_contas: Funcoes.float2Preco(result.data.total_contas)
                });
                carregaMovimentacoes(false, 1, token);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const carregaMovimentacoes = (carrega, filtro, token) => {
        if(carrega){
            setLoading(true);
        }

        if(token === undefined){
            token = user.token;
        }

        ApiService.get('dashboard/movimentacoes?periodo=' + filtro, token)
            .then(result => {
                const res = result.data;
                var horas = ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'];
                var labels = [];
                var entrada = [];
                var saida = [];
                var maxValue = 0;
                var maxLength = (res.saida.length > res.entrada.length ? res.saida.length : res.entrada.length);

                if (res.entrada.length > 0) {
                    if (filtro === 1 || filtro === 2) {
                        for (var i = 0; i < res.entrada.length; i++) {
                            labels.push(res.entrada[i].intervalo + "h");
                            entrada.push(res.entrada[i].valor);
                            if (res.entrada[i].valor > maxValue)
                                maxValue = res.entrada[i].valor;
                        }
                        for (var x = entrada.length; x < labels.length; x++) {
                            entrada.push(0);
                        }
                    } else {
                        for (var i = 0; i < maxLength; i++) {
                            labels.push(res.entrada[i].intervalo.split('-')[2]);
                            entrada.push(res.entrada[i].valor);
                            if (res.entrada[i].valor > maxValue)
                                maxValue = res.entrada[i].valor;
                        }
                    }
                } else {
                    if (filtro === 1 || filtro === 2) {
                        labels = horas;
                    } else {
                        if (filtro === 3) {
                            for (var i = 1; i <= 7; i++) {
                                labels.push('0' + i);
                            }
                        }
                        if (filtro === 4) {
                            for (var i = 1; i <= 30; i++) {
                                labels.push(i < 10 ? '0' + i : i);
                            }
                        }
                    }
                    for (var i = 0; i < labels.length; i++) {
                        entrada.push(0);
                    }
                }

                if (res.saida.length > 0) {
                    if (filtro === 1 || filtro === 2) {
                        for (var i = 0; i < res.saida.length; i++) {
                            if(labels.length < 1){
                                labels.push(res.saida[i].intervalo + "h");
                            }
                            saida.push(res.saida[i].valor);
                            if (res.saida[i].valor > maxValue)
                                maxValue = res.saida[i].valor;
                        }
                        for (var x = saida.length; x < labels.length; x++) {
                            saida.push(0);
                        }
                    } else {
                        for (var i = 0; i < res.saida.length; i++) {
                            if(labels.length < 1){
                                labels.push(res.saida[i].intervalo.split('-')[2]);
                            }
                            saida.push(res.saida[i].valor);
                            if (res.saida[i].valor > maxValue)
                                maxValue = res.saida[i].valor;
                        }
                    }
                } else {
                    if (filtro === 1 || filtro === 2) {
                        labels = horas;
                    } else {
                        if (filtro === 3) {
                            for (var i = 1; i <= 7; i++) {
                                labels.push('0' + i);
                            }
                        }
                        if (filtro === 4) {
                            for (var i = 1; i <= 30; i++) {
                                labels.push(i < 10 ? '0' + i : i);
                            }
                        }
                    }
                    for (var i = 0; i < labels.length; i++) {
                        saida.push(0);
                    }
                }

                if(maxValue < 100){
                    maxValue = maxValue + 10;
                }else if(maxValue < 1000){
                    maxValue = maxValue + 100;
                }else if(maxValue < 10000){
                    maxValue = maxValue + 1000;
                }

                setMainChart({
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                label: "Entrada", fill: false, lineTension: 0.1, backgroundColor: '#44DA6A', borderColor: '#44DA6A',
                                borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#44DA6A',
                                pointBackgroundColor: '#44DA6A', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#44DA6A',
                                pointHoverBorderColor: '#44DA6A', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                                data: entrada
                            },
                            {
                                label: 'Saída', fill: false, lineTension: 0.1, backgroundColor: '#EB5757', borderColor: '#EB5757',
                                borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#EB5757',
                                pointBackgroundColor: '#EB5757', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#EB5757',
                                pointHoverBorderColor: '#EB5757', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                                data: saida
                            }
                        ]
                    },
                    options: {
                        showScale: true, showLines: true, legend: { display: false, },
                        scales: {
                            xAxes: [{ gridLines: { display: false } }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true, min: 0, max: maxValue,
                                    callback: function (value) {
                                        return value < 1000 ? value : Funcoes.nFormatter(value);
                                    }
                                }
                            }]
                        }
                    }
                });
                if(carrega){
                    setLoading(false);
                }else{
                    carregaResumo(token);
                }
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const carregaResumo = (token) => {
        ApiService.get('dashboard/resumo', token)
            .then(result => {
                const res = result.data;
                setResumo(res);
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const handleFilter = (filterSelected) => {
        setFilter(filterSelected);
        carregaMovimentacoes(true, filterSelected);
    }

    const solicitarSaque = () => {
        if(banco === ''){
            setAlertType(2);
            setAlertText('Selecione o banco!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(agencia === ''){
            setAlertType(2);
            setAlertText('Preencha o campo agência corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(numero === ''){
            setAlertType(2);
            setAlertText('Preencha o número da conta corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(valor === 0){
            setAlertType(2);
            setAlertText('Informe um valor para o saque!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(valor > Funcoes.preco2float(saldo.saldo_disponivel)){
            setAlertType(2);
            setAlertText('O valor informado para o saque é maior que o valor disponível!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(senha === ''){
            setAlertType(2);
            setAlertText('Preencha o campo senha corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else{
            setLoading(true);
            const conta = numero.slice(0, -1);
            const digito = numero.substring(numero.length - 1);
            const formData = {
                "banco": banco,
                "agencia": agencia,
                "conta": numero + '-' +digito,
                "valor": valor,
                "senha": senha
            };
            ApiService.post('dashboard/saque', formData, user.token)
                .then(result => {
                    setLoading(false);
                    setAlertType(1);
                    setAlertText('Saque solicitado com sucesso.');
                    setModal(false);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    if (result.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        setPath('/');
                    }
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
        }
    }

    const handleValor = (event, maskedvalue, floatvalue) => {
       setValor(floatvalue);
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} subText={alertType === 1 ? '*solicitações realizadas após às 14h serão processadas no próximo dia útil.' : ''} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Modal show={modal} largura={390}>
                <div className="modalConfirm">
                    <h3>Atenção</h3>
                    <img src={iconAviso} alt="Icone de atenção" />
                    <p>Informe os dados da conta bancária de sua titularidade para a qual deseja realizar o saque.</p>
                    <div style={{ textAlign: 'left' }}>
                        <Input type="select" label="Banco" optionsData={bancos} divStyle={{ marginBottom: 27 }} onChange={e => setBanco(e.target.value)} />
                        <div className="multiInput">
                            <Input type="tel" label="Agência" onChange={e => setAgencia(e.target.value)} divStyle={{ maxWidth: 120, marginRight: 29 }} />
                            <Input type="tel" label="Conta (com dígito)" onChange={e => setNumero(e.target.value)} divStyle={{ maxWidth: 220 }} />
                        </div>
                        <Input type="money" label="Valor do saque" value={valor} onChangeEvent={handleValor} divStyle={{ marginBottom: 27 }} />
                        <Input type="password" label="Senha" value={senha} onChange={e => { setSenha(e.target.value); }} />
                    </div>
                    <button className="btn btnConfirm" onClick={() => { solicitarSaque() }}>Solicitar saque</button>
                    <button className="btnCancelar" onClick={() => setModal(false)}>cancelar</button>
                </div>
            </Modal>
            <SideMenu menuAtivo={1} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="rowCenter">
                        <div className="panel">
                            <p>Saldo Disponível</p>
                            <h3>R$ {saldo.saldo_disponivel}</h3>
                        </div>
                        <div className="panel">
                            <p>À Liberar/Bloqueado</p>
                            <h3 className="cinza">R$ {saldo.saldo_bloqueado}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
