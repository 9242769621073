import React, { useState } from 'react';

import '../resources/css/generals.scss';
import '../resources/css/pages.scss';
import '../resources/css/responsive.scss';

import logoExossWallet from '../resources/img/logo-paypexwallet-branco.png';

import Redirect from '../Components/RedirectScreen';
import Loading from '../Components/LoaderScreen';
import Alert from '../Components/Alert';
import Input from '../Components/Input';
import ApiService from '../Utils/services';
import Funcoes from '../Utils/functions';

export default function Login() {

    const [redirect, setRedirect] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const validaCampos = async () => {
        if(email === ''){
            setAlertType(2);
            setAlertMessage('Preencha o campo email corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(Funcoes.validaEmail(email) !== true){
            setAlertType(2);
            setAlertMessage('O email digitado não é um email valido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(password === ''){
            setAlertType(2);
            setAlertMessage('Preencha o campo senha corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else{
            setLoading(true);
            const formData = {
                "email": email,
                "senha": password
            };
            await ApiService.post('login', formData)
                .then(result => {
                    setLoading(false);
                    const response = result.data;
                    sessionStorage.setItem('userData', JSON.stringify(response));
                    setRedirect(true);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    console.log(result);
                    setLoading(false);
                    setAlertMessage(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
        }
    }


    const handleSubmit = (event) => {
        if (event.key === "Enter") {
            validaCampos();
        }
    }

    return (
        <>
            <Redirect redirect={redirect} path="/home" />
            <div className="login">
                <div className="contentLogin">
                    <div className="panel">
                        <img src={logoExossWallet} width="300px" alt="Logo Exoss Wallet" />

                        <div className="acessaForm">
                            <Input type="email" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={e => { handleSubmit(e) }} />
                            <Input type="password" placeholder="Senha" value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => { handleSubmit(e) }} />
                            <button className="btn entrar" onClick={() => validaCampos()}>Entrar</button>
                        </div>
                    </div>
                </div>
                <Loading show={loading} />
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => setAlert(false)} />
            </div>
        </>
    )
}